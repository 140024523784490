
export const BASEURL = "https://hcltech.eu-west-1.openloyalty-stage.io"; //"http://fabricsupplychain.eastus2.cloudapp.azure.com:3000/api/v1"
//http://fabricsupplychain.eastus2.cloudapp.azure.com:3000/api/v1/suppliermanufacturer/po/add
export const SIGNUPURL = BASEURL + "/user/signup" ;
//export const LOGINURL = "https://fabricsupplychain.eastus2.cloudapp.azure.com:5085/api/authmanager/oauthlogin/dev?redirectui=sustainchainrest";
export const LOGINURL =  BASEURL +"/api/TrueValue/member/login_check";

export const LOGINDEVURL = LOGINURL;
export const LOGOUTDEVURL = "https://authflexiclientblockchain.dna11.hclets.com/api/authmanager/oauthlogout/dev?redirectui=http://localhost:3000/login"


export const SEARCH_TRX = BASEURL + '/searchtransaction/';//txId
