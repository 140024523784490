import React, { useState,useEffect } from 'react';
import * as URL from '../urls';
import useAPIs from '../customHooks/useAPIs';
import { useSnackbar } from 'notistack';
import axios from 'axios';
//import './Login.css'; // Assuming you have a CSS file for styles

function Login() {
  //const loginAPI = useAPIs();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    setError('');
    if (!username || !password) {
      setError('Both fields are required');
      return;
    }
    try {
      const response = await axios.post(URL.LOGINURL, {
        username,
        password,
      });
      // Handle successful login here (e.g., store token, redirect)
      console.log(response.data);
      sessionStorage.clear()
      sessionStorage.setItem("access_token", response.data.token );
      sessionStorage.setItem("username",username)
      
      enqueueSnackbar("Login successful! Welcome back!",  {variant:'success'});
      //alert()
      window.location = "/myrewards"
    } catch (error) {
      enqueueSnackbar("Login failed. Please try again.",  {variant:'error'});
      setError(error.response?.data?.message || 'Login failed. Please try again.');
    }
    // try {
    //   console.log({username, password})
    //   loginAPI.fetchData(URL.LOGINURL,"POST",{username, password},false) 
     
    //   // Handle successful login (e.g., store token, redirect)
    //   //console.log(response.data);
    // } catch (err) {
    //   setError(err.response?.data?.message || 'Login failed. Please try again.');
    // }
  };



// useEffect(()=>{
//   console.log(loginAPI?.response)
// },[loginAPI?.response]) //[composit, catlogListAPI])

  return (
    <div className="container">
      <div className="login-page">
        <p className="text-login">
          Login in to get started
          <br />
        </p>
        <div className="listitem---pointsforpurchasescampaign">
          <input
            type="text"
            className="group-901"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="group-901"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="text-password-0">Password Help</p>
          {error && <p className="error">{error}</p>}
          <button className="group-91" onClick={handleLogin}>
            <div className="wrapper-text-login">
              <p className="text-login-0">LOGIN</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
