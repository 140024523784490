import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Layout from './components/layouts/Layout';
import Login from './components/Login';
import MyRewards from './components/MyRewards';
import NotFound from './components/NotFound';
import Register from './components/Register';
function App() {
  return (
    <Router>
      
       <Routes>
          {/* <Route path="/" element={<Layout />} />
          <Route path="/login" element={<Login />} /> */}
       
        <Route path="/" element={<Layout type={1}/>}>
          <Route
            path="/"
            element={<Dashboard />}
          />
          </Route>
            <Route path="/" element={<Layout type={3}/>}>
            <Route
              path="/myrewards"
              element={<MyRewards />}
            />
          </Route>
          <Route path="/" element={<Layout type={2}/>}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="/" element={<Layout type={4}/>}>
            <Route path="register" element={<Register />} />
          </Route>
      </Routes>
    
  </Router>
  );
}

export default App;
