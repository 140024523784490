
import React, { useEffect,useState } from 'react'
import { Outlet } from 'react-router-dom';
import '../../App.css'
import Footer from '../Footer';
import Header from '../Header';
import PreHeader from '../PreHeader'

function Layout(props) {

  const [isLoggedIn,setIsLoggedIn] = useState(false)
  useEffect(()=>{
    console.log(props.type) 
   })
  useEffect(()=>{

    let session = sessionStorage.getItem("access_token")
    if(session != null){
      setIsLoggedIn(true)
    }
    else{
      setIsLoggedIn(false)
    }
  },[window.location.pathname])

  return <div className="container">
  <div className="my-rewards-page">
    
     
    <PreHeader />   
    <Header type={props.type} />       
    <div className='mainContainer'>
    <Outlet />
    </div>
    
    
    <Footer />
  </div>
</div>

       
  
;
}

export default Layout;