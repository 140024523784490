import React from 'react'

const PreHeader = () => {
  return (
    
    <div className="backgroundhorizontalborder">
        <div className="promo-offer">
        <div className="group-2 wrap">
        <p className="text-save">Save up to 20% on orders over $99 </p>
        <div className="svg">
            <img className="svg-0" src="images/SVG35-95.png" />
        </div>
        </div>
        </div>
  </div>
  )
}

export default PreHeader;
