import React, { useEffect,useState } from 'react';
import * as URL from '../urls';
import useAPIs from '../customHooks/useAPIs';
import { useSnackbar } from 'notistack';
import Loader from './Loader';

const Register = () => {
    const { enqueueSnackbar } = useSnackbar();
    const mainAPI =useAPIs();
    const adminCredAPI = useAPIs();
    const activateAPI = useAPIs();
    const store = "TrueValue";
    const register_url = URL.BASEURL + "/api/"+store+"/member/register";
    const activate_url = URL.BASEURL + "/api/"+store+"/member/";
    const [customerId,setCustomerId] = useState();
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        plainPassword:'',
     //   birthDate: '',
        gender: 'female',
        loyaltyCardNumber: '',
        referrerToken: '',
     //   registeredAt: '',
     //   tier: '',
     //   primaryStoreId: '',
        agreement1: true,
        agreement2: true,
        agreement3: true
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
          ...form,
          [name]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        form.loyaltyCardNumber = ( form?.phone || form?.email)
        console.log('Form submitted:', form);

        // Add form submission logic here
        mainAPI.fetchData(register_url,"POST",{"customer":form},false)     
      };
    
      useEffect(()=>{
        console.log(mainAPI?.response)
        if(mainAPI?.response){
          enqueueSnackbar("Thank you for joining our TrueValue loyalty program! Your enrollment is complete, and you're now on your way to earning exciting rewards.",  {variant:'success'});
          
          setCustomerId(mainAPI?.response?.customerId)
          const lg = {
            username: "johnnydont@cogniverse.net",
            password: "Openloyalty@123"
          }
          adminCredAPI.fetchData(URL.BASEURL + "/api/admin/login_check","POST",lg,false)  

        }
        if(mainAPI?.error){
          enqueueSnackbar("Oops! Something went wrong with your enrollment. Please try again later or contact our support team for assistance. We're here to help you get started with our loyalty program as soon as possible.",  {variant:'error'});
        }
        
      },[mainAPI?.response,mainAPI?.error])


      useEffect(()=>{
        console.log(adminCredAPI?.response, )
    
        //adminMembAPI.fetchData(URL.BASEURL + "/api/"+store+"?loyaltyCardNumber="+ mem_details?.loyaltyCardNumber,"GET",null,false)  
        if(adminCredAPI?.response){
          //setAdminToken(adminCredAPI?.response.token)
          activateAPI.fetchDataByToken(activate_url+customerId+"/activate","POST",{},adminCredAPI?.response.token)
          console.log(activate_url+customerId+"/activate");
        };
       
      },[adminCredAPI?.response])

      useEffect(()=>{
        console.log(activateAPI)
        if(activateAPI?.response == "" && activateAPI?.error ==""){
          enqueueSnackbar("Your enrollment is complete, and you're now redirected to Login page.",  {variant:'success'});
          setTimeout(()=>{window.location="/login"},5000)
        }

      },[activateAPI?.response])
    
      const handleCancel = () => {
        setForm({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            
            plainPassword:'',
           // birthDate: '',
            gender: 'female',
            loyaltyCardNumber: '',
            referrerToken: '',
          //  registeredAt: '',
         //   tier: '',
         //   primaryStoreId: '',
            agreement1: true,
            agreement2: true,
            agreement3: true,
        });
      };

      
    
      return (
     
          <div className="register-page">
            <h2 className="title">Register to get started!</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-section">
                <h3>General Information</h3>
                <div className="form-group">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={form.firstName}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={form.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={form.email}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={form.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                <input
                    type="password"
                    name="plainPassword"
                    placeholder="Password"
                    value={form.plainPassword}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  {/* <input
                    type="datetime-local"
                    name="birthDate"
                    placeholder="Date of Birth"
                    value={form.birthDate}
                    onChange={handleChange}
                  /> */}
                 
                  <div className="gender-group">
                    
                    <h3 style={{paddingRight:20}}>Gender</h3> 
                   
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={form.gender === 'female'}
                      onChange={handleChange}
                    />
                    <label>Female</label>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={form.gender === 'male'}
                      onChange={handleChange}
                    />
                    <label>Male</label>
                    <input
                      type="radio"
                      name="gender"
                      value="other"
                      checked={form.gender === 'other'}
                      onChange={handleChange}
                    />
                    <label>Other</label>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <h3>Other Information</h3>
                <div className="form-group">
                  {/* <input
                    type="text"
                    name="loyaltyCardNumber"
                    placeholder="Loyalty Card Number"
                    value={form.loyaltyCardNumber}
                    onChange={handleChange}
                  /> */}
                  <input
                    type="text"
                    name="referrerToken"
                    placeholder="Referrer Token"
                    value={form.referrerToken}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  {/* <input
                    type="datetime-local"
                    name="registeredAt"
                    placeholder="Registration Date"
                    value={form.registeredAt}
                    onChange={handleChange}
                  /> */}
                  {/* <input
                    type="text"
                    name="tier"
                    placeholder="Tier"
                    value={form.tier}
                    onChange={handleChange}
                  /> */}
                </div>
                <div className="form-group">
                  {/* <input
                    type="text"
                    name="primaryStoreId"
                    placeholder="Primary Store ID"
                    value={form.primaryStoreId}
                    onChange={handleChange}
                  /> */}
                </div>
              </div>
              <div className="form-actions">
                <button type="submit" className="btn register-btn">REGISTER</button>
                <button type="button" className="btn cancel-btn" onClick={handleCancel}>CANCEL</button>
              </div>
            </form>
            {(mainAPI.loading || activateAPI.loading || adminCredAPI.loading) && <Loader />}
          </div>
       
      );
    }

export default Register