import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header(props) {
 console.log(props.type, "header")
  return (
    <div className="mainContainer ">
    <div className="wrapper-true_value_logo-1-link wrap">
      <div className="true_value_logo-1">
      <Link to="/"><img
          className="true_value_logo-2"
          src="images/true_value_logo 135-111.png"
        /></Link>
        
      </div>
      
      <nav className="top-nav text-shop">
      <ul>
        <li><Link to="/shop-all">SHOP ALL</Link></li>
        <li><Link to="/shop-by-benefit">SHOP BY BENEFIT</Link></li>
        <li><Link to="/shop-by-brand">SHOP BY BRAND</Link></li>
        <li><Link to="/rewards">REWARDS</Link></li>
        <li><Link to="/store-locator">STORE LOCATOR</Link></li>
      </ul>
    </nav>
   <div className="wrapper-icons-0 wrap">
      <div className="icons wrap top-nav text-shop">

     <ul>
     {(props.type != 2 &&  props.type != 4 )  && <>
      <li><Link to="/shop-all"><img className="icons-0" src="images/search.png" /></Link></li>
            <li><Link to="/shop-by-benefit"><img className="icons-0" src="images/user.png" /></Link></li>
           
     </>}
           
            <li><Link to="/shop-by-brand"><img className="icons-0" src="images/Vector.png" /></Link></li>
           
        </ul> 
      </div>
      {props.type == 3 && <img
        className="mask-group-1-1"
        src="images/Mask Group 1 135-124.png"
      />}
    {(props.type == 1 || props.type == 4) && <button className="login-header" onClick={()=> window.location="/login"} >
      <div className="wrapper-text-login">
        <p className="text-login-0">LOGIN</p>
      </div>
    </button>}
    {props.type == 2 && <button className="login-header" onClick={()=> window.location="/register"} >
      <div className="wrapper-text-login">
        <p className="text-login-0">Register</p>
      </div>
    </button>}
    
       </div>
    </div>
  
  </div>
  )
}

export default Header;