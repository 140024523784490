import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className="container">
    <div className="landing-page">
    
      
      <div className="group-85">
        <div className="wrapper-text-shop">
          
          <div className="group-4 wrap">
            <div className="wrapper-group-3">
              <div className="group-3">
                <p className="text-shop-1">SHOP. SAVE. EARN. </p>
                <p className="text-become">
                  Become a member and earn points &amp;
                  <br />
                  exclusive rewards every time you shop{" "}
                </p>
              </div>
            </div>
            <img
              className="banner_landing-1"
              src="images/banner_landing 11-46.png"
            />
          </div>
          <button className="group-5">
            <div className="group-83">
              <div className="wrapper-text-join">
              
                <p className="text-join"><Link to="/register">JOIN NOW</Link> </p>
              </div>
            </div>
          </button>
          <button className="group-7">
          <div className="group-83">
              <div className="wrapper-text-join">
                <p className="text-join"><Link to="/login">LOGIN</Link>  </p>
              </div>
            </div>
          </button>
        </div>
      </div>
      <p className="text-ways">Ways to earn points </p>
      <div className="group-77">
        <div className="wrapper-listitem---facebookpagevisitcampaign wrap">
          <div className="listitem---pointsforpurchasescampaign">
            <div className="image_2023_10_17_08_04_03_694">
              <img
                className="image_2023_10_17_08_04_03_694-0"
                src="images/image_2023_10_17_08_04_03_6941-61.png"
              />
            </div>
            <p className="text-make">Make a Purchase </p>
            <p className="text-happy">Earn Points at select stores </p>
          </div>
          <div className="listitem---createaccountcampaign">
            <div className="image_2023_10_17_08_05_42_718">
              <img
                className="image_2023_10_17_08_05_42_718-0"
                src="images/image_2023_10_17_08_05_42_7181-80.png"
              />
            </div>
            <p className="text-make">200 points </p>
            <p className="text-happy">Create an account </p>
          </div>
          <div className="listitem---instagramfollowcampaign">
            <div className="image_2023_10_17_08_05_05_537">
              <img
                className="image_2023_10_17_08_05_05_537-0"
                src="images/image_2023_10_17_08_05_05_5371-94.png"
              />
            </div>
            <p className="text-make">10 points </p>
            <p className="text-happy">Follow us on Instagram </p>
          </div>
          
            <div className="listitem---instagramfollowcampaign">
              <div className="group-75-1">
                <img
                  className="group-75-2"
                  src="images/Group 75 11-182.png"
                />
              </div>
              <p className="text-make">100 points </p>
              <p className="text-happy">Happy Birthday </p>
            </div>
       
        </div>
        <div className="wrapper-listitem---facebookpagevisitcampaign wrap">
          <div className="listitem---facebookpagevisitcampaign-0">
            <div className="image_2023_10_17_08_05_25_673">
              <img
                className="image_2023_10_17_08_05_25_673-0"
                src="images/image_2023_10_17_08_05_25_6731-107.png"
              />
            </div>
            <p className="text-make">10 points </p>
            <p className="text-happy">Like on Facebook </p>
          </div>
          <div className="listitem---facebookpagevisitcampaign-1">
            <div className="image_2023_10_17_08_03_09_765">
              <img
                className="image_2023_10_17_08_03_09_765-0"
                src="images/image_2023_10_17_08_03_09_7651-120.png"
              />
            </div>
            <p className="text-make">10 points </p>
            <p className="text-happy">TikTok Follow </p>
          </div>
          <div className="listitem---facebookpagevisitcampaign-2">
            <div className="image_2023_10_17_08_06_12_002">
              <img
                className="image_2023_10_17_08_06_12_002-0"
                src="images/image_2023_10_17_08_06_12_0021-140.png"
              />
            </div>
            <p className="text-make">25 points </p>
            <p className="text-happy">Complete our quiz </p>
          </div>
          <div className="listitem---facebookpagevisitcampaign-3">
            <div className="image_2023_10_17_08_03_34_660">
              <img
                className="image_2023_10_17_08_03_34_660-0"
                src="images/image_2023_10_17_08_03_34_6601-160.png"
              />
            </div>
            <p className="text-13">10 points </p>
            <p className="text-happy">Sign up for emails </p>
          </div>
        </div>
      </div>
      <p className="text-how">How to use your points </p>
      <p className="text-redeeming">
        Redeeming your hard-earned points is easy! Simply apply your points for a
        discount at checkout!{" "}
      </p>
      <div className="border">
        <p className="text-500">500 points equals $1.00 </p>
      </div>
      <div className="group-78">
        <div className="wrapper-text-5 wrap">
          <p className="text-5">
          <span className='text-red'  >$1</span>
            <br />
            500 points{" "}
          </p>
          <p className="text-14">
          <span className='text-red' >$5</span>
            <br />
            2500 points{" "}
          </p>
        </div>
        <div className="wrapper-text-15 wrap">
          <p className="text-15">
          <span className='text-red'  >$10</span>
            <br />
            5000 points{" "}
          </p>
          <p className="text-20">
          <span className='text-red'  >$20</span>
            <br />
            10000 points{" "}
          </p>
        </div>
      </div>
      <div className="group-87">
        <div className="wrapper-text-get">
          <p className="text-get">GET 20% OFF FOR EVERY FRIEND YOU REFER </p>
          <p className="text-give">
            Give your friends 20% off and you'll get 20% off after their first
            purchase.{" "}
          </p>
          <div className="input---your-email-address">
            <div className="container">
              <p className="text-your-email">Your email address </p>
            </div>
          </div>
          <button className="group-79">
            <div className="group-86">
              <div className="wrapper-text-start">
                <p className="text-start">START SHARING </p>
              </div>
            </div>
          </button>
        </div>
      </div>
     
    </div>
  </div>
  
  )
}

export default Dashboard