import { useState, useEffect } from 'react';
import { getFromSession } from '../utils';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {LOGINURL,LOGINDEVURL} from "../urls"


const useAPIs = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState();
    const navigate = useNavigate()
    let headers = null;
    let session_id = null;
    //let url=null;
    //let method = "GET";
    //let body = null;
    //const currentprofile = JSON.parse(sessionStorage.getItem("currentOrg"))
    let orgid = null
    //let at = "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImFwcElkLTQwYWFkYzExLTc2NzYtNDJjZC1hNDllLWU1Yzg5NzNlMTEyNi0yMDIyLTA5LTE5VDA2OjAzOjQxLjgyNyIsInZlciI6NH0.eyJpc3MiOiJodHRwczovL2F1LXN5ZC5hcHBpZC5jbG91ZC5pYm0uY29tL29hdXRoL3Y0LzQwYWFkYzExLTc2NzYtNDJjZC1hNDllLWU1Yzg5NzNlMTEyNiIsImV4cCI6MTY4NzQ0ODM0MywiYXVkIjpbIjgyYzFhNzYwLTdmNGYtNGEwNy1iYmMwLTJlYzk4NTM4ZWVmNiJdLCJzdWIiOiI4MmMxYTc2MC03ZjRmLTRhMDctYmJjMC0yZWM5ODUzOGVlZjYiLCJhbXIiOlsiYXBwaWRfY2xpZW50X2NyZWRlbnRpYWxzIl0sImlhdCI6MTY4NzQxMjM0MywidGVuYW50IjoiNDBhYWRjMTEtNzY3Ni00MmNkLWE0OWUtZTVjODk3M2UxMTI2Iiwic2NvcGUiOiJhcHBpZF9kZWZhdWx0In0.Q5hqM7_JI0HX9qha43g8AnHYjjFVUH6M7LfMBBo981a-HdQsG73Rpt5wMK00Zvm6ai4n_8lTmRL2FH6V3145w5M8_Q8KmVmO31r8-7uR-fwZJOJVNcFQ_h0UM68xND24UWu3wG3-myMmx37xLqbWUDBmcYEMAnxz82sH_1ryOWEoMOqG5IEC08b_MKI1WXIKY-SMyFQAbUrtOMCU5r-TkpSKVAa89Y3Kif2Gl4YW_bQi4aRysdpMAuac-dZ2aPtDh85PeTajgHA6TRTJUAwAdqc2I_efO_Ak0VqmbT-PlmzbX1_mTtppCJNY8BGOMj2eXjTKr6lTvAzfNZSGZZydoQ";
   
    const fetchData = (url_path,method,body,isAccessTokenInHedars) => {
       
        let url = url_path
        // if(url_path == PROFILEURL || url_path == LOGINURL || url_path == FILEUPLOADURL){
            
        // }
        // else{
        //     url = profile.url + "/api/v1" + url_path
        // }
        console.log(url)
        setloading(true);
        headers = {
          
        }
        // if(url_path == FILEUPLOADURL){
        //     headers["Content-Type"] = 'multipart/form-data;';
            
        //     //headers['content-length']= `${body.size}`;
        // }
        if(isAccessTokenInHedars){
            let session = sessionStorage.getItem("access_token")
            headers["Authorization"] = 'Bearer '+  session          
        }
       
        
        if(url)
            axios({          
                url: url,                      
                method : method,
                headers: headers,
                data: body,
            })
            .then((res) => {
                setResponse(res.data);
                setloading(false)
            })
            .catch((err) => {
                setError(err);
                console.log(err)
                console.log(err?.response?.status)
                if(err?.response?.status == 401){              
                        //window.location =  LOGINURL              
                    if (window.location.origin == 'http://localhost:3000' ){              
                        window.location = LOGINURL              
                        console.log("local")              
                    }              
                    else{              
                        window.location = LOGINDEVURL;              
                        console.log("indev")            
                    }
                }
    setloading(false)
            })
            .finally(() => {
                setloading(false);
            });
    };

    const fetchDataByToken = (url_path,method,body,tokenp) => {
       
        let url = url_path
        // if(url_path == PROFILEURL || url_path == LOGINURL || url_path == FILEUPLOADURL){
            
        // }
        // else{
        //     url = profile.url + "/api/v1" + url_path
        // }
        console.log(url)
        setloading(true);
        headers = {
          
        }
        // if(url_path == FILEUPLOADURL){
        //     headers["Content-Type"] = 'multipart/form-data;';
            
        //     //headers['content-length']= `${body.size}`;
        // }
        headers["Authorization"] = 'Bearer '+  tokenp 
       
        
        if(url)
            axios({          
                url: url,                      
                method : method,
                headers: headers,
                data: body,
            })
            .then((res) => {
                setResponse(res.data);
                setloading(false)
            })
            .catch((err) => {
                setError(err);
                console.log(err)
                console.log(err?.response?.status)
                if(err?.response?.status == 401){              
                        //window.location =  LOGINURL              
                    if (window.location.origin == 'http://localhost:3000' ){              
                        window.location = LOGINURL              
                        console.log("local")              
                    }              
                    else{              
                        window.location = LOGINDEVURL;              
                        console.log("indev")            
                    }
                }
    setloading(false)
            })
            .finally(() => {
                setloading(false);
            });
    };
    ////console.log(fetchData);
    return { response, error, loading, fetchData,fetchDataByToken  };
};

export default useAPIs ;