import React from 'react'
const transactions = [
    {
      description: 'Reward points on birthday',
      type: 'Credit',
      points: 50,
      status: 'Approved',
      expirationDate: 'Monday, June 22, 2024'
    },
    {
      description: 'Order ID #00012342341',
      type: 'Credit',
      points: 10,
      status: 'Approved',
      expirationDate: 'Friday, June 19, 2024'
    },
    {
      description: 'Order ID #00045621965',
      type: 'Credit',
      points: 20,
      status: 'Approved',
      expirationDate: 'Tuesday, June 16, 2024'
    },
    {
      description: 'Order ID #00012342341',
      type: 'Credit',
      points: 10,
      status: 'Approved',
      expirationDate: 'Friday, June 19, 2024'
    }
  ];
const DataTable = (props) => {
    //const  {transaction} = props;
    const data = props?.data 
    return (
        <div className="transaction-table-container">
        
        <table className="transaction-table">
            <thead>
                <tr>
                    <th>Invoice Number</th>
                    <th>Gross Value</th>
                    <th>Transaction Type</th>
                    <th>Transaction Time</th>
                    <th>Store</th>
                    <th>Points Earned</th>
                </tr>
            </thead>
            <tbody>
                {data?.items?.map((item, index) => (
                    <tr key={index}>
                        <td>{item?.header?.documentNumber}</td>
                        <td>{item?.grossValue}</td>
                        <td>{item?.header?.documentType=="sell"?"Purchase":item?.header?.documentType}</td>
                        <td>{new Date(item?.header?.purchasedAt).toLocaleString()}</td>
                        <td>{item?.header?.purchasePlace}</td>
                        <td>{ parseInt(item?.pointsEarned)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
};

export default DataTable