import React from 'react'

const Carousel = () => {
  return (
    <>
     <p className="text-best">Best Sellers </p>
      <div className="wrapper-arrow_left-1 wrap">
        <div className="arrow_left-1">
          <img
            className="arrow_left-2"
            src="images/arrow_left 136-223.png"
          />
        </div>
        <div className="wrapper-text-dewalt">
          <p className="text-dewalt">
            DEWALT 50 PC. 1/4 IN. Drive Mechanics Tool Set (DWMT81610){" "}
          </p>
          <p className="text-price">Price : $32.99 </p>
          <p className="text-200">16000 Points </p>
          <button className="group-94">
            <div className="wrapper-text-buy">
              <p className="text-buy">BUY NOW </p>
            </div>
          </button>
          <div className="rectangle-58" />
          <img className="line-1" src="images/Line 135-183.png" />
          <img
            className="dewalt_dwmt81610-1"
            src="images/dewalt_dwmt81610 148-251.png"
          />
        </div>
        <div className="wrapper-text-price">
          <p className="text-price-0">Price : $98.50 </p>
          <p className="text-weber">
            Weber Charcoal Grill Smokey Joe Premium 37cm Black{" "}
          </p>
          <p className="text-300">49000 Points </p>
          <div className="rectangle-59" />
          <img className="line-2" src="images/Line 235-214.png" />
          <img
            className="weber_smokey_new-1"
            src="images/weber_smokey_new 146-237.png"
          />
          <button className="group-97">
            <div className="wrapper-text-buy-0">
              <p className="text-buy-0">BUY NOW </p>
            </div>
          </button>
        </div>
        <div className="wrapper-text-price-text-scotts">
          <p className="text-price-1">Price : $92.00 </p>
          <p className="text-scotts">
            Scotts® Turf Builder® EdgeGuard® DLX Broadcast Spreader{" "}
          </p>
          <p className="text-301">45000 Points </p>
          <div className="rectangle-60" />
          <img className="line-3" src="images/Line 335-222.png" />
          <button className="group-98">
            <div className="wrapper-text-buy-1">
              <p className="text-buy-1">BUY NOW </p>
            </div>
          </button>
          <img
            className="scotts_turfbuilder-1"
            src="images/scotts_turfbuilder 148-246.png"
          />
        </div>
        <div className="arrow_left-3">
          <img
            className="arrow_left-4"
            src="images/arrow_left 236-226.png"
          />
        </div>
      </div>
    </>
  )
}

export default Carousel;