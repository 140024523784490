import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="backgroundhorizontalborder-0">
        <div className="bgframe-svg mainContainer">
          <div className="group-81 wrap">
            <p className="text-about">
              About True Value
              <br />
              Refer &amp; Get 20% Off
              <br />
              Become a Member
              <br />
              True Value Rewards
            </p>
            <div className="group-80 wrap">
                
            <div className="icons wrap top-nav text-shop">

<ul>
    <li><Link to="/shop-all"><img className="icons-0" src="images/fb.png" /></Link></li>
    <li><Link to="/shop-by-benefit"><img className="icons-0" src="images/insta.png" /></Link></li>
    <li><Link to="/shop-by-brand"><img className="icons-0" src="images/youtube.png" /></Link></li>
    <li><Link to="/shop-by-benefit"><img className="icons-0" src="images/music.png" /></Link></li>
    <li><Link to="/shop-by-brand"><img className="icons-0" src="images/pinterest.png" /></Link></li>

</ul> 
</div>
            </div>
            <p className="text-contact">
              Contact
              <br />
              Find a Store
              <br />
              Careers
              <br />
              Help Center{" "}
            </p>
          </div>
        </div>
        <p className="text-">
          © 2024 True Value Company, L.L.C. All Rights Reserved{" "}
        </p>
      </div>
  )
}

export default Footer