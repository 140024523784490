import React, { useEffect,useState } from 'react'
import Carousel from './Carousel';
import { useSnackbar } from 'notistack';
import * as URL from '../urls';
import useAPIs from '../customHooks/useAPIs';
import DataTable from './DataTable';
import axios from 'axios';
import DataTableActivity from './DataTableActivity';
import Loader from './Loader';

function MyRewards() {
  const { enqueueSnackbar } = useSnackbar();
  const mainAPI =useAPIs();  
  const adminCredAPI =useAPIs()
  const adminMembAPI =useAPIs() 
  const TxAPI =useAPIs()  
  const ActivityPAPI = useAPIs()
  const redeemAPI =useAPIs() 
  const [mem_details,setMem_details] = useState([])
  const [tx_details,setTx_details] = useState([])
  const [atx_details,setATx_details] = useState([])
  const [adminToken,setAdminToken] = useState()
  
  const store = "TrueValue";
  const reward_id= "c2fe7e21-06f3-4f35-b310-5885912362fc";
  
  const loyaltyCardNumber = sessionStorage.getItem("username");
  let member_id = "loyaltyCardNumber="+loyaltyCardNumber ; //username //"d2580cfa-59a4-4e62-b126-2e69580c57b1";
  if(loyaltyCardNumber.includes('@')){
    member_id = "email="+loyaltyCardNumber    
  }
 
  const details_url = URL.BASEURL + "/api/"+store+"/member/"+member_id
  const Tx_url = URL.BASEURL + "/api/" + store + "/transaction?customerData:loyaltyCardNumber="+loyaltyCardNumber
  const ActivityP_url = URL.BASEURL + "/api/" + store + "/member/points?_orderBy[createdAt]=desc"

  const redeem_url = URL.BASEURL+ "/api/" + store +"/reward/"+reward_id+"/buy"

  
 
 
  useEffect(()=>{
    const lg = {
      username: "johnnydont@cogniverse.net",
      password: "Openloyalty@123"
    }
    adminCredAPI.fetchData(URL.BASEURL + "/api/admin/login_check","POST",lg,false)  
  },[])

  useEffect(()=>{
    console.log(adminCredAPI?.response, )

    //adminMembAPI.fetchData(URL.BASEURL + "/api/"+store+"?loyaltyCardNumber="+ mem_details?.loyaltyCardNumber,"GET",null,false)  
    if(adminCredAPI?.response){
      setAdminToken(adminCredAPI?.response.token)
      mainAPI.fetchDataByToken(details_url,"GET",null,adminCredAPI?.response.token)
    }
   
  },[adminCredAPI?.response])

  const redeemPoints=()=>{

    const data= {
     // "customerId": member_id,  //"87d8e330-2878-4742-a86f-dbbb3bf522ac",
       "quantity": 1,   
      // "withoutPoints": true,
    }
    redeemAPI.fetchData(redeem_url,"POST",data,true)  

  }
  useEffect(()=>{
    if(redeemAPI?.response){
      enqueueSnackbar("Redeemed sucessfully",  {variant:'success'});      
      mainAPI.fetchDataByToken(details_url,"GET",null,adminToken)
      ActivityPAPI.fetchData(ActivityP_url,"GET",null,true)
      TxAPI.fetchData(Tx_url,"GET",null,true)   
    }
   
  },[redeemAPI?.response])

  useEffect(()=>{
  //  mainAPI.fetchData(details_url,"GET",null,true)  
    ActivityPAPI.fetchData(ActivityP_url,"GET",null,true)
    TxAPI.fetchData(Tx_url,"GET",null,true)     
  },[])

  useEffect(()=>{
    setMem_details(mainAPI?.response)
    console.log(mainAPI?.response)
    
  },[mainAPI?.response])
 
 

   useEffect(()=>{
    console.log(adminMembAPI?.response)
   },[adminMembAPI?.response])

  useEffect(()=>{
    setTx_details(TxAPI?.response)
    console.log(TxAPI?.response)
  },[TxAPI?.response])

  useEffect(()=>{
    setATx_details(ActivityPAPI?.response)
    
  },[ActivityPAPI?.response])

  return <>
   <div  className="wrapper-backgroundhorizontalborder">
        <p className="text-my">My Reward </p>
    </div>
    <div className="wrapper-wrapper-wrapper-image-1 wrap">
      <div className="wrapper-wrapper-image-1">
        <div className="wrapper-image-1 wrap">
          <img className="image-1" src="images/image 135-130.png" />
          <div className="wrapper-text-28650">
            <p className="text-28650"> <span className='rewardPoints'> { isNaN(parseInt(mem_details?.defaultAccount?.activePoints)) ? 0 :parseInt(mem_details?.defaultAccount?.activePoints)}</span> Reward Points </p>
            <p className="text-current">Current Balance </p>
            <p className="text-5">
              Reward points expire after 2 years of inactivity.
            </p>
          </div>
        </div>
        <div className="wrapper-wrapper-background">
          <div className="wrapper-background wrap">
            <button className="background">
              <div className="listitem">
                <p className="text-total">Total Earned </p>
                <p className="text-430">{ isNaN((mem_details?.defaultAccount?.earnedPoints)) ? 0 : parseInt(mem_details?.defaultAccount?.earnedPoints)   } </p>
              </div>
            </button>
            <button className="background-0">
              <div className="listitem">
                <p className="text-total-0">Total Spend </p>
                <p className="text-430">{ isNaN((mem_details?.defaultAccount?.spentPoints)) ? 0 : parseInt(mem_details?.defaultAccount?.spentPoints) } </p>
              </div>
            </button>
            <button className="background-locked">
              <div className="listitem">
                <p className="text-expired">Points Locked </p>
                <p className="text-430">{ isNaN((mem_details?.defaultAccount?.lockedPoints)) ? 0 : parseInt(mem_details?.defaultAccount?.lockedPoints) } </p>
              </div>
            </button>
            <button className="background-1">
              <div className="listitem">
                <p className="text-expired">Expired </p>
                <p className="text-430">{ isNaN(mem_details?.defaultAccount?.expiredPoints) ? 0 : parseInt(mem_details?.defaultAccount?.expiredPoints)} </p>
              </div>
            </button>
          </div>
          <div className="border">
            <p className="text-your">
            Your rewards referenced Id is: {mem_details?.loyaltyCardNumber || 0}
            </p>
          </div>
        </div>
      </div>
      <div className="listitem---facebookpagevisitcampaign">
        <p className="text-your-0">Your Profile </p>
        <div className="group-92">
          <div className="wrapper-text-70">
            <p className="text-70">70% </p>
          </div>
        </div>
        <p className="text-complete">
          Complete your profile to earn extra 50 points{" "}
        </p>
        <button className="group-90">
          <div className="wrapper-text-complete">
            <p className="text-complete-0">COMPLETE </p>
          </div>
        </button>
      </div>
    </div>
    {/* <div className='redeem-section'>
      <p>Claim $5 for your 2500 points
      <button onClick={redeemPoints} className='redeem-link'> Redeem here </button></p>
    </div> */}
    <div className="group-36345">
  <div className="wrapper-text-5 wrap">
    <div className="wrapper-text-5-group-90">
      <p className="text-51">
        $5 for every 2,500 points
      

      </p>
      <p className="text-52">
        Members-only offers
      </p>
      <button className="group-90" onClick={redeemPoints} >
        <div className="wrapper-text-redeem">
          <p className="text-redeem">REDEEM NOW </p>
        </div>
      </button>
    </div>
    <img
      className="image-2190"
      src="https://figma-alpha-api.s3.us-west-2.amazonaws.com/images/5594f305-23e1-42bc-bd97-97500b5f21fb"
    />
  </div>
</div>
    <div className="wrapper-wrapper-text-recent">
      <div className="wrapper-text-recent wrap">
        <p className="text-recent">Recent Transactions </p>
        <button className="group-93">
          <div className="wrapper-text-view">
            <p className="text-view">View All Transactions </p>
          </div>
        </button>
      </div>
      
        <DataTable data={tx_details} />

        <br /><br /><br />
        <div className="wrapper-text-recent wrap">
        <p className="text-recent">Activity Points </p>
       
      </div>

      <DataTableActivity data={atx_details} />
        <Carousel />
        {(mainAPI.loading || TxAPI.loading || ActivityPAPI.loading || redeemAPI.loading || adminCredAPI.loading  ) && <Loader />}
    </div>
  </>;
}

export default MyRewards;